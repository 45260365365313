<template>
  <div class="bg-grey-1">
    <Header />
    <div class="mx-4 md:mx-44 my-4 flex flex-col gap-8">
      <div class="flex gap-4">
        <img :src="detailToko.foto_toko" alt="" class="h-20 w-20 rounded-full">
        <div class="grid gap-4">
          <h2>{{ detailToko.nama_toko }}</h2>
          <p>{{ detailToko.alamat_toko }}</p>
        </div>
      </div>
      <div class="border-b border-gray-400">
        <button class="rounded-t-xl border border-gray-400 bg-white px-4 py-1">Package</button>
      </div>
      <div class="grid grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-2">
        <div v-for="paket in listPaket" :key="paket.id">
          <CardPaketNew :data="paket" :key="paket.id" class="cursor-pointer" @toggleWishlist="toggleWishlist"
            @selectPackage="selectPackage" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import FilterPaketNew from "../components/FilterPaketNew.vue";
import SearchBarNew from "../components/SearchBarNew.vue";
import CardPaketNew from "../components/CardPaketNew.vue";
import DetailPaketHeroNew from "../components/DetailPaketHeroNew.vue";
import BookingDetailCardNew from "../components/BookingDetailCardNew.vue";
import { Slugify } from '@/utils';
export default {
  name: "ListPaketToko",
  components: {
    Header,
    Footer,
    FilterPaketNew,
    SearchBarNew,
    CardPaketNew,
    DetailPaketHeroNew,
    BookingDetailCardNew,
  },
  data() {
    return {
      filter: {
        trip_type: "safari",
        menu: "0", //0: all, 1: water, 2: land, 3 sky
        submenu: "",
        idWilayah: "",
        country: "ID",
        search: "",
        id_toko: "",
      },
      page: {
        prev: "",
        next: ""
      },
      listPaket: [],
      detailToko: {
        id_toko: "",
        nama_toko: "",
        alamat_toko: "",
        foto_toko: ""
      }
    };
  },
  async created() {
    if (this.$route.params.category == 'water') {
      this.filter.menu = '1';
    } else if (this.$route.params.category == 'land') {
      this.filter.menu = '2';
    } else if (this.$route.params.category == 'sky') {
      this.filter.menu = '3';
    } else {
      this.filter.menu = '0';
    }
    if (this.$route.params.id_toko != null) {
      this.filter.id_toko = this.$route.params.id_toko;
    } 
    if (this.$route.query.keyword != null) {
      this.filter.search = this.$route.query.keyword;
    } 

    await this.$nextTick();
    await this.loadDetailToko();
  },
  computed: {
    activeCountryName(){
      if(this.filter.country == 'MY'){
        return "Malaysia";
      }
      return "Indonesia";
    },
    queryUpdate(){
      return this.$route.query.keyword;
    }
  },
  watch: {
    queryUpdate(newVal, oldVal){
      this.filter.search = newVal;
      this.loadPaket();
    }
  },
  methods: {
    async openFilterModal() {
      this.$refs.filterPaketNew.open()
    },
    async loadPaket(url = `${this.$apiTripweWeb}/package/list/all`) {
      this.$store.commit("setOverlayLoading", true);
      try {
        const res = await this.$http.post(
          url,
          {
            id_wilayah: 0,
            nama_wilayah: this.filter.idWilayah,
            nama_paket: this.filter.search,
            submenu: this.filter.submenu,
            menu: this.filter.menu,
            id_toko: this.filter.id_toko,
          }
        );

        this.listPaket = res.data.data.item;
        this.page.prev = res.data.data.prev_page;
        this.page.next = res.data.data.next_page;
      } catch (error) {
        this.$toasted.global.error(
          "Fail to get data trips, please try again later"
        );
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    async loadDetailToko() {
      this.$store.commit("setOverlayLoading", true);
      try {
        const res = await this.$http.post(
          `${this.$apiTripweWeb}/shop/detail`, {
            "slug_url": this.$route.params.slug
        }
        );

        this.detailToko = res.data.data;
        
        this.filter.id_toko = this.detailToko.id_toko;
        await this.loadPaket();
      } catch (error) {
        this.$toasted.global.error(
          "Fail to load data"
        );
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    selectPackage(pack) {
      if(pack.is_rental == 1){
        this.$router.push({
          name: 'Rental',
          params: { id_wilayah: pack.wilayah_toko, id_toko: pack.id_toko }
        });
      }else{
        this.$router.push({
          name: 'PackageDetail',
          params: { slug: pack.slug_url, pack }
        });
      }
    },
    handlePaginate(type) {
      if (type == 'next') {
        this.loadPaket(this.page.next);
      } else {
        this.loadPaket(this.page.prev);
      }
    },
    toggleWishlist(pack) {
      if (pack.idw != "0" && pack.idw != "" && pack.idw != null) {
        this.removeWishlist(pack);
      } else {
        this.addWishlist(pack);
      }
    },
    searchAll() {
      this.filter.idWilayah = "";
      this.loadPaket();
    },
    async addWishlist(pack) {
      this.$store.commit("setOverlayLoading", true);
      var datapost = {
        paket: pack.id,
        toko: pack.id_toko,
        jenis: parseInt(pack.tipe, 10) + 2,
      };

      var res = await this.$http.post(
        `${this.$apiTripweWeb}/wishlist/add`,
        datapost
      );
      if (res.data.status == 20000) {
        pack.idw = res.data.data.id.toString();
      }
      this.$store.commit("setOverlayLoading", false);
    },
    async removeWishlist(pack) {
      this.$store.commit("setOverlayLoading", true);
      var datapost = {
        id_wishlist: pack.idw,
      };

      var res = await this.$http.post(
        `${this.$apiTripweWeb}/wishlist/delete`,
        datapost
      );
      if (res.data.status == 20000) {
        pack.idw = "0";
      }
      this.$store.commit("setOverlayLoading", false);
    },
  },
};
</script>
